import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	const owl = $( '.owl-carousel' );
	owl.owlCarousel( {
		loop: false,
		items: 1,
		autoHeight: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="far fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="far fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	owl.on( 'changed.owl.carousel', function ( e ) {
		const logo = $( 'header h1' );
		const prev = $( '.owl-prev' );
		if ( e.item.index > 0 ) {
			logo.addClass( 'shown' );
			prev.hide();
		} else {
			logo.removeClass( 'shown' );
			prev.show();
		}
	} );

	$( '.player a' ).on( 'click', function ( e ) {
		e.preventDefault();

		if ( $( this ).find( 'i' ).hasClass( 'fa-play-circle' ) ) {
			$( 'audio' ).each( function () {
				$( this )[0].pause();
			} );
			$( '.player a i' ).addClass( 'fa-play-circle' ).removeClass( 'fa-pause-circle' );
			$( this ).find( 'i' ).removeClass( 'fa-play-circle' ).addClass( 'fa-pause-circle' );
			$( this ).next( 'audio' )[0].play();
		} else {
			$( '.player a i' ).addClass( 'fa-play-circle' ).removeClass( 'fa-pause-circle' );
			$( 'audio' ).each( function () {
				$( this )[0].pause();
			} );
		}
	} );
} );
